import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Media.module.css'

const Media = ({ className, style, position, children, figure }) => {
  const bodyElement = (
    <div key="body" className={styles.body}>
      {children}
    </div>
  )
  const figureElement = (
    <div key="figure" className={styles.figure}>
      {figure}
    </div>
  )

  return (
    <div className={cx(styles.root, className)} style={style}>
      {position === 'left' ? [figureElement, bodyElement] : [bodyElement, figureElement]}
    </div>
  )
}

Media.propTypes = {
  children: PropTypes.node,
  figure: PropTypes.node,
  position: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Media
