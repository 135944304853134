import React from 'react'

import { FaLifeRing, FaCar, FaCouch } from 'react-icons/fa'
import { MdRestaurant } from 'react-icons/md'

import Lead from '@components/Lead'
import PaddedBlock from '@components/PaddedBlock'
import Media from '@components/Media'
import SEO from '@components/SEO'
import Title from '@components/Title'
import Link from '@components/Link'

const ServicesPage = () => (
  <React.Fragment>
    <SEO title="Services" />

    <PaddedBlock>
      <Lead>
        <abbr title="Motor Yatch">M/Y</abbr> Christina is available for charter for your special event
      </Lead>
      <article>
        <p>Below are some of the services provided by M/Y Christina.</p>
        <Media position="right" figure={<MdRestaurant />}>
          <Title level={3}>Food, Drinks & Catering</Title>
          <p>Our waiters will be happy to serve you.</p>
          <p>
            If you are bringing food, or make someone cater it, we will provide the plates, cutlery, glasses, and even
            ice cubes for your drinks <i>free of charge</i>.
          </p>
        </Media>
        <p>
          We can also facilitate things, by <b>catering the event</b>. To ask for this service, specify it in the{' '}
          <b>comments</b> section in our <Link to="/reservation/">reservation</Link> form.
        </p>
        <Media position="left" figure={<FaLifeRing />}>
          <Title level={3}>Your Security</Title>
          <p>Your security is our number one goal.</p>
          <p>
            We have been <strong>in the Nile Cruise business since 1992</strong>, and hold all the necessary piloting
            licenses.
          </p>
          <p>
            We have <b>life jackets</b> for all people that are allowed on board. And we also have{' '}
            <b>fire and smoke detectors</b>.
          </p>
        </Media>
        <Media position="right" figure={<FaCouch />}>
          <Title level={3}>Your Comfort</Title>
          <p>
            Our yacht can accommodate up to <b>25 people</b> comfortably.
          </p>
          <p>
            The <b>front and rear deck</b> are both accessible in addition to the <b>closed area</b>. Also we have{' '}
            <b>two toilets</b> on board.
          </p>
        </Media>
        <Media position="left" figure={<FaCar />}>
          <Title level={3}>Free Parking</Title>
          <p>We know how hard it is to park around the neighborhood.</p>
          <p>
            M/Y Christina offers you free parking for <strong>4 cars</strong> in front of our docking area.
          </p>
        </Media>
      </article>
    </PaddedBlock>
  </React.Fragment>
)

export default ServicesPage
